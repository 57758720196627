import React, { Component } from 'react';

import { ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';

class ParkingTypeToggle extends Component {
  render() {
    return (
      <div>
        <ButtonToolbar>
          <ToggleButtonGroup name={'type'} type="radio" defaultValue={0}>
            {this.props.parkingTypes.map((item, i) => {
              return (
                <ToggleButton value={i} key={i} onChange={this.props.changeType.bind(this, i)}>
                  {item.name}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </ButtonToolbar>
      </div>
    );
  }
}

export default ParkingTypeToggle;
